import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const payments = `/${service}/payments`;

export function getPayments() {
  const url = `${payments}`;
  return {
    method: GET,
    url,
  };
}

export function getPaymentById(id) {
  const url = `${payments}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updatePayment(id, body) {
  const url = `${payments}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deletePayment(id) {
  const url = `${payments}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createPayment(body) {
  const url = `${payments}`;
  return {
    method: POST,
    url,
    body,
  };
}
