import { GET_BAZAAR_CURRENT_CUSTOMER } from "constants/bazaarCustomers";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_BAZAAR_CURRENT_CUSTOMER}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
