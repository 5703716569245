import { networkAction } from "helpers/network/networkAction";

import {
  GET_CART_COUPONS,
  GET_CART_COUPON_BY_ID,
  DELETE_CART_COUPON,
  UPDATE_CART_COUPON,
  CREATE_CART_COUPON,
} from "constants/cartCoupons";

import * as Api from "api";

export const getCartCoupons = () => async (dispatch) =>
  networkAction(dispatch, GET_CART_COUPONS, Api.getCartCoupons, []);

export const getCartCouponById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CART_COUPON_BY_ID, Api.getCartCouponById, [id]);

export const deleteCartCoupon = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CART_COUPON, Api.deleteCartCoupon, [id]);

export const createCartCoupon = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CART_COUPON, Api.createCartCoupon, [body]);

export const updateCartCoupon = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CART_COUPON, Api.updateCartCoupon, [id, body]);
