import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const cards = `/${service}/cards`;

export function getCards() {
  const url = `${cards}`;
  return {
    method: GET,
    url,
  };
}

export function setCardAsFavorite(cardID) {
  const url = `${cards}/${cardID}/setDefault`;
  return {
    method: PUT,
    url,
  };
}

export function getCardById(id) {
  const url = `${cards}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateCard(id, body) {
  const url = `${cards}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCard(id) {
  const url = `${cards}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCard(body) {
  const url = `${cards}`;
  return {
    method: POST,
    url,
    body,
  };
}
