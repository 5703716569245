import { GET_SEARCH_PRESETS } from "constants/searchPresets";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_SEARCH_PRESETS}_SUCCESS`:
      if (action.payload) {
        return action.payload;
      }
      return state;
    default:
      return state;
  }
};
