import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const carts = `/${service}/carts`;

export function getCarts() {
  const url = `${carts}`;
  return {
    method: GET,
    url,
  };
}

export function getCurrentCart() {
  const url = `${carts}/current`;
  return {
    method: GET,
    url,
  };
}

export function payCart(cartID, body) {
  const url = `${carts}/${cartID}/pay`;
  return {
    method: POST,
    url,
    body,
  };
}

export function getCartById(id) {
  const url = `${carts}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateCart(id, body) {
  const url = `${carts}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCart(id) {
  const url = `${carts}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCart(body) {
  const url = `${carts}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function validateCart(body) {
  const url = `${carts}/validate`;
  return {
    method: POST,
    url,
    body,
  };
}
