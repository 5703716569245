import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const cartGoods = `/${service}/cartGoods`;

export function getCartGoods() {
  const url = `${cartGoods}`;
  return {
    method: GET,
    url,
  };
}

export function getCartGoodById(id) {
  const url = `${cartGoods}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateCartGood(id, body) {
  const url = `${cartGoods}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCartGood(id) {
  const url = `${cartGoods}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCartGood(body) {
  const url = `${cartGoods}`;
  return {
    method: POST,
    url,
    body,
  };
}
