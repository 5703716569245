import { networkAction } from "helpers/network/networkAction";

import {
  GET_CART_GOODS,
  GET_CART_GOOD_BY_ID,
  DELETE_CART_GOOD,
  UPDATE_CART_GOOD,
  CREATE_CART_GOOD,
} from "constants/cartGoods";

import * as Api from "api";

export const getCartGoods = () => async (dispatch) =>
  networkAction(dispatch, GET_CART_GOODS, Api.getCartGoods, []);

export const getCartGoodById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CART_GOOD_BY_ID, Api.getCartGoodById, [id]);

export const deleteCartGood = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CART_GOOD, Api.deleteCartGood, [id]);

export const createCartGood = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CART_GOOD, Api.createCartGood, [body]);

export const updateCartGood = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CART_GOOD, Api.updateCartGood, [id, body]);
