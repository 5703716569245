import { networkAction } from "helpers/network/networkAction";

import {
  GET_BAZAAR_CUSTOMERS,
  GET_BAZAAR_CUSTOMER_BY_ID,
  GET_BAZAAR_CUSTOMER_FULL,
  DELETE_BAZAAR_CUSTOMER,
  UPDATE_BAZAAR_CUSTOMER,
  CREATE_BAZAAR_CUSTOMER,
  GET_BAZAAR_CURRENT_CUSTOMER,
} from "constants/bazaarCustomers";

import * as Api from "api";

export const getBazaarCustomers = () => async (dispatch) =>
  networkAction(dispatch, GET_BAZAAR_CUSTOMERS, Api.getBazaarCustomers, []);

export const getBazaarCustomerFull = (id) => async (dispatch) =>
  networkAction(dispatch, GET_BAZAAR_CUSTOMER_FULL, Api.getBazaarCustomerFull, [
    id,
  ]);

export const getBazaarCurrentCustomer = () => async (dispatch) =>
  networkAction(
    dispatch,
    GET_BAZAAR_CURRENT_CUSTOMER,
    Api.getBazaarCurrentCustomer,
    []
  );

export const getBazaarCustomerById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_BAZAAR_CUSTOMER_BY_ID,
    Api.getBazaarCustomerById,
    [id]
  );

export const deleteBazaarCustomer = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_BAZAAR_CUSTOMER, Api.deleteBazaarCustomer, [
    id,
  ]);

export const createBazaarCustomer = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_BAZAAR_CUSTOMER, Api.createBazaarCustomer, [
    body,
  ]);

export const updateBazaarCustomer = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_BAZAAR_CUSTOMER, Api.updateBazaarCustomer, [
    id,
    body,
  ]);
