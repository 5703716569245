import { networkAction } from "helpers/network/networkAction";

import {
  GET_CARDS,
  GET_CARD_BY_ID,
  DELETE_CARD,
  UPDATE_CARD,
  CREATE_CARD,
  SET_CARD_AS_FAVORITE,
} from "constants/cards";

import * as Api from "api";

export const getCards = () => async (dispatch) =>
  networkAction(dispatch, GET_CARDS, Api.getCards, []);

export const getCardById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CARD_BY_ID, Api.getCardById, [id]);

export const setCardAsFavorite = (id) => async (dispatch) =>
  networkAction(dispatch, SET_CARD_AS_FAVORITE, Api.setCardAsFavorite, [id]);

export const deleteCard = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CARD, Api.deleteCard, [id]);

export const createCard = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CARD, Api.createCard, [body]);

export const updateCard = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CARD, Api.updateCard, [id, body]);
