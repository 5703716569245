import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "account";
const organizationUsers = `${REACT_APP_API_PREFIX}/${service}/organizationUsers`;

export function getOrganizationUsers(filters) {
  const url = `${organizationUsers}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getOrganizationUserById(id) {
  const url = `${organizationUsers}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateOrganizationUser(id, body) {
  const url = `${organizationUsers}/${id}/invite`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteOrganizationUser(id) {
  const url = `${organizationUsers}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createOrganizationUser(body) {
  const url = `${organizationUsers}`;
  return {
    method: POST,
    url,
    body,
  };
}
