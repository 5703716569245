import { Checkbox, Grid, IconButton, InputBase } from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function TmpOrganizationUser({ user, onDelete, index }) {
  const classes = useStyles(styles);

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <IconButton size="small" onClick={() => onDelete(index)}>
          <Close style={{ fontSize: 16 }} />
        </IconButton>
      </Grid>
      {/* <Grid item xs={3}>
        <InputBase
          className={classes.inputBase}
          defaultValue={user.firstName}
          onChange={(e) => (user.firstName = e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <InputBase
          className={classes.inputBase}
          defaultValue={user.lastName}
          onChange={(e) => (user.lastName = e.target.value)}
        />
      </Grid> */}
      <Grid item xs={9}>
        <InputBase
          fullWidth
          className={classes.inputBase}
          defaultValue={user.inviteEmail}
          onChange={(e) => (user.inviteEmail = e.target.value)}
        />
      </Grid>
      <Grid item>
        <Checkbox
          color="primary"
          onChange={(e) => (user.instantJoin = e.target.checked)}
          defaultChecked={user.instantJoin}
        />
      </Grid>
    </Grid>
  );
}
