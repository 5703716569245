import { networkAction } from "helpers/network/networkAction";

import {
  GET_PAYMENTS,
  GET_PAYMENT_BY_ID,
  DELETE_PAYMENT,
  UPDATE_PAYMENT,
  CREATE_PAYMENT,
} from "constants/payments";

import * as Api from "api";

export const getPayments = () => async (dispatch) =>
  networkAction(dispatch, GET_PAYMENTS, Api.getPayments, []);

export const getPaymentById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PAYMENT_BY_ID, Api.getPaymentById, [id]);

export const deletePayment = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_PAYMENT, Api.deletePayment, [id]);

export const createPayment = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PAYMENT, Api.createPayment, [body]);

export const updatePayment = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PAYMENT, Api.updatePayment, [id, body]);
