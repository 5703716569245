import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const customers = `/${service}/customers`;

export function getBazaarCustomers() {
  const url = `${customers}`;
  return {
    method: GET,
    url,
  };
}

export function getBazaarCurrentCustomer() {
  const url = `${customers}/current`;
  return {
    method: GET,
    url,
  };
}

export function getBazaarCustomerById(id) {
  const url = `${customers}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getBazaarCustomerFull() {
  const url = `${customers}/current`;
  return {
    method: GET,
    url,
  };
}

export function updateBazaarCustomer(id, body) {
  const url = `${customers}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteBazaarCustomer(id) {
  const url = `${customers}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createBazaarCustomer(body) {
  const url = `${customers}`;
  return {
    method: POST,
    url,
    body,
  };
}
