import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const cartCoupons = `/${service}/cartCoupons`;

export function getCartCoupons() {
  const url = `${cartCoupons}`;
  return {
    method: GET,
    url,
  };
}

export function getCartCouponById(id) {
  const url = `${cartCoupons}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateCartCoupon(id, body) {
  const url = `${cartCoupons}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCartCoupon(id) {
  const url = `${cartCoupons}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCartCoupon(body) {
  const url = `${cartCoupons}`;
  return {
    method: POST,
    url,
    body,
  };
}
