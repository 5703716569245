import { GET_CURRENT_CART, VALIDATE_CART } from "constants/carts";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_CURRENT_CART}_SUCCESS`:
    case `${VALIDATE_CART}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
