import * as Api from "api";
import {
  CREATE_ORGANIZATION_USER,
  DELETE_ORGANIZATION_USER,
  GET_ORGANIZATION_USERS,
  GET_ORGANIZATION_USER_BY_ID,
  UPDATE_ORGANIZATION_USER,
} from "constants/organizationUsers";
import { networkAction } from "helpers/network/networkAction";

export const getOrganizationUsers = () => async (dispatch) =>
  networkAction(dispatch, GET_ORGANIZATION_USERS, Api.getOrganizationUsers, []);

export const getOrganizationUserById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_ORGANIZATION_USER_BY_ID,
    Api.getOrganizationUserById,
    [id]
  );

export const deleteOrganizationUser = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    DELETE_ORGANIZATION_USER,
    Api.deleteOrganizationUser,
    [id]
  );

export const createOrganizationUser = (body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_ORGANIZATION_USER,
    Api.createOrganizationUser,
    [body]
  );

export const updateOrganizationUser = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    UPDATE_ORGANIZATION_USER,
    Api.updateOrganizationUser,
    [id, body]
  );
